.my-search {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1360px;
  flex-direction: column;
  padding: 30px;
}

.my-search-wrap {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 9999999;
  pointer-events: none;
  transition: opacity 0.5s ease-in;
  box-sizing: border-box;
}

.my-search-wrap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
  background: rgba(235, 242, 253, 0.82);
}

.my-search-wrap:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: rgba(0, 61, 166, 0.82);
}

.my-search * {
  box-sizing: border-box;
}

.my-search__active {
  visibility: visible;
  opacity: 1;
  pointer-events: painted;
}

.my-search__close {
  position: absolute;
  top: 70px;
  right: 30px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}

.my-search__close:after, .my-search__close:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 44px;
  height: 2px;
  background: #FFFFFF;
}

.my-search__close:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.my-search__close:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.my-search__close:hover {
  transform: rotate(90deg);
}

.my-search__block {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  flex: 0 0 auto;
  margin: 40px 80px 15%;
  border-bottom: 2px solid #EBF2FD;
}

.my-search__label {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
}

.my-search__text {
  background: transparent;
  border: none;
  font-family: "PFDinTextUniversal-Light";
  font-weight: 300;
  font-size: 96px;
  color: #EBF2FD;
  line-height: 1em;
  width: 100%;
  height: 100%;
}

.my-search__text::placeholder {
  font-family: "PFDinTextUniversal-Light";
  font-weight: 300;
  font-size: 96px;
  line-height: 1em;
  color: #2361C2;
}

.my-search__text:focus {
  border: none;
  outline: none;
}

.my-search__icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  display: block;
  height: 47px;
  width: 47px;
  cursor: pointer;
  visibility: hidden;
}

.my-search__icon svg {
  height: 47px;
}

.my-search__icon img {
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.my-search__icon-audio line:nth-child(1) {
  transform-origin: bottom;
  animation: audio 0.5s ease-in infinite alternate-reverse;
  animation-play-state: paused;
}

.my-search__icon-audio line:nth-child(2) {
  transform-origin: bottom;
  animation: audio 0.6s ease-in infinite alternate-reverse;
  animation-play-state: paused;
}

.my-search__icon-audio line:nth-child(3) {
  transform-origin: bottom;
  animation: audio 0.7s ease-in infinite alternate-reverse;
  animation-play-state: paused;
}

.my-search__icon-audio line:nth-child(4) {
  transform-origin: bottom;
  animation: audio 0.8s ease-in infinite alternate-reverse;
  animation-play-state: paused;
}

.my-search__icon-audio line:nth-child(5) {
  transform-origin: bottom;
  animation: audio 0.9s ease-in infinite alternate-reverse;
  animation-play-state: paused;
}

.my-search__icon-audio line:nth-child(6) {
  transform-origin: bottom;
  animation: audio 1s ease-in infinite alternate-reverse;
  animation-play-state: paused;
}

.my-search__icon-audio line:nth-child(7) {
  transform-origin: bottom;
  animation: audio 1.1s ease-in infinite alternate-reverse;
  animation-play-state: paused;
}

.my-search__icon-audio line:nth-child(8) {
  transform-origin: bottom;
  animation: audio 1.2s ease-in infinite alternate-reverse;
  animation-play-state: paused;
}

.my-search__icon-shadow {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: rgba(0, 30, 81, 0.2);
  transform: translate(-50%, -50%) scale(2.5);
}

.my-search__icon-shadow-1 {
  animation: callAnimate 4.5s cubic-bezier(0.57, 0, 0.26, 0.83) infinite;
  z-index: -3;
}

.my-search__icon-shadow-2 {
  animation: callAnimate 4.5s cubic-bezier(0.57, 0, 0.26, 0.83) infinite 1.5s;
  z-index: -2;
}

.my-search__icon-shadow-3 {
  animation: callAnimate 4.5s cubic-bezier(0.57, 0, 0.26, 0.83) infinite 3s;
  z-index: -1;
}

.my-search__icon.active {
  visibility: visible;
}

.my-search__icon.active line {
  animation-play-state: running;
}

.my-search__description {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0, 100%);
  padding: 10px 0;
  font-size: 16px;
  line-height: 26px;
  color: #EBF2FD;
  text-align: right;
}

.my-search__result {
  display: flex;
  flex-direction: column;
  margin: 0 0 50px;
  padding: 0 80px;
  flex: 1 1 100%;
  overflow: auto;
  /* Track */
  /* Handle */
}

.my-search__result-empty {
  font-size: 24px;
  line-height: 38px;
  color: #EBF2FD;
}

.my-search__result-elem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 0;
}

.my-search__result a {
  font-size: 24px;
  line-height: 1em;
  text-decoration-line: none;
  color: #EBF2FD;
  margin: 0 auto 25px 0;
  border-bottom: 2px solid #EBF2FD;
}

.my-search__result span {
  font-size: 16px;
  line-height: 26px;
  text-align: right;
  color: #799BD0;
}

.my-search__result::-webkit-scrollbar {
  width: 2px;
}

.my-search__result::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
}

.my-search__result::-webkit-scrollbar-thumb {
  background: #003796;
  -webkit-box-shadow: none;
}

.my-search__result::-webkit-scrollbar-thumb:window-inactive {
  background: #003796;
}

.my-search-start {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: 24px;
  cursor: pointer;
  z-index: 6678;
  margin: 0 10px 0 30px;
}

.my-search-start-icon {
  height: 24px;
  width: 24px;
  fill: #FFFFFF;
}

.my-search__help-micro {
  position: absolute;
  bottom: -80px;
  right: 0;
  padding: 20px;
  background: #003796;
  transform: translate(0, 100%);
  z-index: 5;
  font-family: "PFDinTextUniversal-Regular";
  visibility: hidden;
  pointer-events: none;
  transition: visibility 0.4s ease-in;
}

.my-search__help-micro:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 13px;
  border-style: solid;
  border-bottom-color: #003796;
  border-right-color: #003796;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: translate(0, -100%);
}

.my-search__help-micro--img {
  position: absolute;
  top: 0;
  left: 50%;
  height: 52px;
  width: 52px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.my-search__help-micro--close {
  position: relative;
  display: block;
  height: 25px;
  width: 25px;
  margin: 0 0 0 auto;
  cursor: pointer;
}

.my-search__help-micro--close:after, .my-search__help-micro--close:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 2px;
  background: #ffffff;
}

.my-search__help-micro--close:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.my-search__help-micro--close:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.my-search__help-micro--title {
  font-family: "PFDinTextUniversal-Bold";
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #EBF2FD;
}

.my-search__help-micro--text {
  font-family: "PFDinTextUniversal-Regular";
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  text-transform: lowercase;
  color: #EBF2FD;
}

.my-search__help-micro.my-active {
  visibility: visible;
  pointer-events: painted;
}

.my-search__test {
  width: 100%;
  text-align: center;
  font-family: "PFDinTextUniversal-Regular";
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 1em;
  text-transform: uppercase;
  color: #ffffff;
}

.my-search__lang {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  flex: 1 0 auto;
  margin: 20px 80px 20px;
}

.my-search__lang-img svg {
  height: 41px;
  width: 33px;
}

.my-search__lang-title {
  font-family: "PFDinTextUniversal-Regular";
  font-weight: normal;
  font-size: 24px;
  line-height: 1em;
  color: #EBF2FD;
  margin: 0 30px 0;
}

.my-search__lang-button {
  flex: 1 1 auto;
  height: 40px;
  padding: 0 10px;
  color: #799BD0;
  background: #003DA6;
  border: none;
  cursor: pointer;
}

.my-search__lang-button-wrap {
  display: flex;
  flex: 1 1 auto;
  margin: 10px 0;
}

.my-search__lang-button.active {
  background: #ffffff;
  color: #003DA6;
  cursor: no-drop;
  pointer-events: none;
}

.my-search__lang-button:focus {
  outline: 0 solid #ffffff;
}

@keyframes audio {
  to {
    transform: scaleY(0);
  }
}

@keyframes callAnimate {
  from {
    transform: translate(-50%, -50%) scale(0.1);
  }
  65% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media screen and (max-width: 1024px) {
  .my-search {
    padding: 20px;
  }
  .my-search__close {
    top: 67px;
    height: 25px;
    width: 25px;
    margin: 0 0 0 auto;
  }
  .my-search__close:after, .my-search__close:before {
    width: 20px;
    height: 2px;
  }
  .my-search__block {
    margin: 30px 40px 10%;
    border-bottom: 2px solid #EBF2FD;
  }
  .my-search__lang {
    margin: 20px 40px;
  }
  .my-search__text {
    font-size: 78px;
  }
  .my-search__text::placeholder {
    font-size: 78px;
  }
  .my-search__icon {
    height: 36px;
    width: 36px;
  }
  .my-search__icon svg {
    height: 36px;
    width: 36px;
  }
  .my-search__description {
    font-size: 14px;
    line-height: 22px;
  }
  .my-search__result {
    margin: 0 0 40px;
    padding: 0 40px;
  }
  .my-search__result-empty {
    font-size: 20px;
    line-height: 32px;
  }
  .my-search__result-elem {
    padding: 0 15px 0 0;
  }
  .my-search__result a {
    font-size: 20px;
    margin: 0 auto 15px 0;
  }
  .my-search__result span {
    font-size: 14px;
    line-height: 22px;
  }
  .my-search-start {
    height: 48px;
    width: 20px;
    margin: 0 20px 0 25px;
  }
  .my-search-start-icon {
    height: 20px;
    width: 20px;
  }
}

@media screen and (max-width: 767px) {
  .my-search__close {
    top: 15px;
    right: 15px;
    height: 20px;
    width: 20px;
  }
  .my-search__block {
    height: 52px;
    margin: 30px 0 50px;
    border-bottom: 1px solid #EBF2FD;
  }
  .my-search__lang {
    margin: 20px 0;
  }
  .my-search__text {
    font-size: 36px;
    line-height: 1.4em;
  }
  .my-search__text::placeholder {
    font-size: 36px;
  }
  .my-search__icon {
    height: 25px;
    width: 25px;
  }
  .my-search__icon svg {
    height: 25px;
    width: 25px;
  }
  .my-search__description {
    display: none;
    font-size: 12px;
    line-height: 14px;
  }
  .my-search__result {
    margin: 0 0 30px;
    padding: 0;
  }
  .my-search__result-empty {
    font-size: 20px;
    line-height: 32px;
  }
  .my-search__result-elem {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin-bottom: 15px;
  }
  .my-search__result a {
    font-size: 16px;
    margin: 0 0 5px;
    border-bottom: 1px solid #EBF2FD;
  }
  .my-search__result span {
    font-size: 12px;
    line-height: 12px;
  }
  .my-search-start {
    height: 44px;
    width: 20px;
    margin: 0 0 0 15px;
  }
  .my-search-start-icon {
    height: 20px;
    width: 20px;
  }
  .my-search__lang {
    justify-content: center;
  }
  .my-search__lang-img svg {
    height: 30px;
    width: 25px;
  }
  .my-search__lang-title {
    font-size: 18px;
    margin: 0 10px;
  }
  .my-search__lang-button {
    flex: 1 1 33.3%;
  }
  .my-search__test {
    letter-spacing: 0.2em;
  }
  .call-phone {
    display: none;
  }
}
